@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ts-chat-box {
  max-height: 450px;
}

.ts-chat-box::-webkit-scrollbar {
  border-radius: 1rem;
  width: 10px;
  background-color: #0b1120;
}
.ts-chat-box::-webkit-scrollbar-track {
  border-radius: 1rem;
  background-color: #e2e8f0;
}
.ts-chat-box::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background-color: #94a3b8;
}
